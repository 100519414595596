import React from 'react';
import { RecoilRoot } from 'recoil';
import '../../libs/brand-system/src/sass/styles.scss';
import '../../libs/growth-platform-brand-system-v2/src/sass/styles.scss';
import './src/styles/global.scss';
import { createRoot } from 'react-dom/client';
import { AnalyticsWrapper } from './scripts/AnalitycsWarpper';

export const wrapPageElement = ({ element, props }) => {
  return (
    <RecoilRoot {...props}>
      <AnalyticsWrapper location={props?.location} path={props.path}>
        {element}
      </AnalyticsWrapper>
    </RecoilRoot>
  );
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = createRoot(container);
    root.render(element);
    return () => {
      root.unmount();
    };
  };
};
